import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"

export default function ThankYou() {
  return (
    <Layout tcoreinsight={false}>
      <div className="flex flex-col items-center max-w-xs mx-auto text-center text-pgray mt-20 mb-40">
        <h1 className="font-black text-3xl">Thank You</h1>
        <p className="text-base font-black mt-6 mb-10">
          We will get in touch with you shortly to complete sign up
        </p>
        <Link
          to="/what-we-do"
          className="w-full py-3 font-black text-white text-center bg-pred"
        >
          Continue
        </Link>
      </div>
    </Layout>
  )
}
